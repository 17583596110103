import React, { Fragment } from "react";
import { SystemProps, Box, Stack, Text } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { getLinkProps } from "~lib";
import { RecipeCard } from "~components/common/RecipeCard";
import { Slider } from "../../../../components";
import { BlogCard } from "../../../../components/common/BlogCard";
import { SeeMoreButton, TitleCombo } from "../molecules/TitleCombo";

type BlogBarProps = {
  first?: boolean;
  block?: boolean;
  content: {
    title: string;
    anchor_id?: string;
    description?: string;
    link_label?: string;
    link_url?: any;
    blog_list: any;
    recipes_ids?: any;
  };
} & SystemProps;

export const BlogBar = ({ content, first, ...props }: BlogBarProps) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeItems = async (): Promise<any> => {
    const ids =
      typeof content.recipes_ids[0] === "number"
        ? content.recipes_ids.map((id) => Number(id))
        : content.recipes_ids.map(({ id }) => Number(id));
    try {
      //@ts-ignore
      let query = supabase
        .rpc("search_recipes_new", {
          search_term: "",
        })
        .in("id", ids);
      const { data } = await query;
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems } = useQuery(
    ["getRecipeItems", content?.recipes_ids],
    getRecipeItems,
    {
      enabled: Boolean(content?.recipes_ids && content?.recipes_ids.length),
    }
  );
  const { query } = useRouter();
  if (recipeItems && recipeItems.length)
    return (
      <Box width={"100%"}>
        <Box
          mb={["16px", "40px"]}
          display={"flex"}
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          alignItems={"center"}
          textAlign={["center", "start"]}
          width={"100%"}
        >
          <Box>
            {content.title && (
              <Text
                lineHeight={"46.8px"}
                fontFamily={"DINPro"}
                fontSize={"36px"}
                fontWeight={700}
              >
                {content.title}
              </Text>
            )}
            {content.description && (
              <Text fontSize={"18px"} lineHeight={"25.2px"}>
                {content.description}
              </Text>
            )}
          </Box>
          {content.link_label && getLinkProps(content.link_url) && (
            <SeeMoreButton
              mt={["20px", 0]}
              link={getLinkProps(content.link_url)}
            >
              {content.link_label}
            </SeeMoreButton>
          )}
        </Box>
        <Box overflow={"hidden"} width={"100%"} display={["none", "block"]}>
          <Slider spaceBetween={"24px"} slidesPerView={4}>
            {recipeItems.map((blog) => (
              <Box key={blog?.id} width={"306px"}>
                <RecipeCard content={blog} mb={[2, 0]} />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box display={["block", "none"]}>
          {recipeItems.map((blog) => (
            <Box mt={2} key={blog?.id}>
              <RecipeCard
                width={["100%", "100%", "23.33%"]}
                content={blog}
                mb={[2, 0]}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  return (
    <Box width="100%" {...props} id={content?.anchor_id}>
      <TitleCombo
        first={first}
        content={content}
        border={query.slug === "green-friday-2022"}
      />
      <Stack
        space={[0, 3]}
        flexDirection={["column", "column", "row"]}
        width="100%"
      >
        {content?.blog_list?.map((blog) => (
          <Fragment key={blog?.id}>
            <BlogCard
              width={["100%", "100%", "23.33%"]}
              {...blog}
              mb={[2, 0]}
            />
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
};
